/* a very simple box-model reset, intentionally does not include pseudo elements */
.graphiql-container * {
  box-sizing: border-box;
  font-variant-ligatures: none;
}

.graphiql-container,
.CodeMirror-info,
.CodeMirror-lint-tooltip,
.graphiql-dialog,
.graphiql-dialog-overlay,
.graphiql-tooltip,
[data-radix-popper-content-wrapper] {
  /* Colors */
  --color-primary: 320, 95%, 43%;
  --color-secondary: 242, 51%, 61%;
  --color-tertiary: 188, 100%, 36%;
  --color-info: 208, 100%, 46%;
  --color-success: 158, 60%, 42%;
  --color-warning: 36, 100%, 41%;
  --color-error: 13, 93%, 58%;
  --color-neutral: 219, 28%, 32%;
  --color-base: 219, 28%, 100%;

  /* Color alpha values */
  --alpha-secondary: 0.76;
  --alpha-tertiary: 0.5;
  --alpha-background-heavy: 0.15;
  --alpha-background-medium: 0.1;
  --alpha-background-light: 0.07;

  /* Font */
  --font-family: 'Roboto', sans-serif;
  --font-family-mono: 'Fira Code', monospace;
  --font-size-hint: calc(12rem / 16);
  --font-size-inline-code: calc(13rem / 16);
  --font-size-body: calc(15rem / 16);
  --font-size-h4: calc(18rem / 16);
  --font-size-h3: calc(22rem / 16);
  --font-size-h2: calc(29rem / 16);
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --line-height: 1.5;

  /* Spacing */
  --px-2: 2px;
  --px-4: 4px;
  --px-6: 6px;
  --px-8: 8px;
  --px-10: 10px;
  --px-12: 12px;
  --px-16: 16px;
  --px-20: 20px;
  --px-24: 24px;

  /* Border radius */
  --border-radius-2: 2px;
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-12: 12px;

  /* Popover styles (tooltip, dialog, etc) */
  --popover-box-shadow: 0px 6px 20px rgba(59, 76, 106, 0.13),
    0px 1.34018px 4.46726px rgba(59, 76, 106, 0.0774939),
    0px 0.399006px 1.33002px rgba(59, 76, 106, 0.0525061);
  --popover-border: none;

  /* Layout */
  --sidebar-width: 60px;
  --toolbar-width: 40px;
  --session-header-height: 51px;
}

@media (prefers-color-scheme: dark) {
  body:not(.graphiql-light) .graphiql-container,
  body:not(.graphiql-light) .CodeMirror-info,
  body:not(.graphiql-light) .CodeMirror-lint-tooltip,
  body:not(.graphiql-light) .graphiql-dialog,
  body:not(.graphiql-light) .graphiql-dialog-overlay,
  body:not(.graphiql-light) .graphiql-tooltip,
  body:not(.graphiql-light) [data-radix-popper-content-wrapper] {
    --color-primary: 338, 100%, 67%;
    --color-secondary: 243, 100%, 77%;
    --color-tertiary: 188, 100%, 44%;
    --color-info: 208, 100%, 72%;
    --color-success: 158, 100%, 42%;
    --color-warning: 30, 100%, 80%;
    --color-error: 13, 100%, 58%;
    --color-neutral: 219, 29%, 78%;
    --color-base: 219, 29%, 18%;

    --popover-box-shadow: none;
    --popover-border: 1px solid hsl(var(--color-neutral));
  }
}

body.graphiql-dark .graphiql-container,
body.graphiql-dark .CodeMirror-info,
body.graphiql-dark .CodeMirror-lint-tooltip,
body.graphiql-dark .graphiql-dialog,
body.graphiql-dark .graphiql-dialog-overlay,
body.graphiql-dark .graphiql-tooltip,
body.graphiql-dark [data-radix-popper-content-wrapper] {
  --color-primary: 338, 100%, 67%;
  --color-secondary: 243, 100%, 77%;
  --color-tertiary: 188, 100%, 44%;
  --color-info: 208, 100%, 72%;
  --color-success: 158, 100%, 42%;
  --color-warning: 30, 100%, 80%;
  --color-error: 13, 100%, 58%;
  --color-neutral: 219, 29%, 78%;
  --color-base: 219, 29%, 18%;

  --popover-box-shadow: none;
  --popover-border: 1px solid hsl(var(--color-neutral));
}

.graphiql-container,
  .CodeMirror-info,
  .CodeMirror-lint-tooltip,
  .graphiql-dialog,
  .graphiql-container:is(button),
  .CodeMirror-info:is(button),
  .CodeMirror-lint-tooltip:is(button),
  .graphiql-dialog:is(button) {
    color: hsl(var(--color-neutral));
    font-family: var(--font-family);
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height);
  }

.graphiql-container input, .CodeMirror-info input, .CodeMirror-lint-tooltip input, .graphiql-dialog input {
    color: hsl(var(--color-neutral));
    font-family: var(--font-family);
    font-size: var(--font-size-caption)
  }

.graphiql-container input::placeholder, .CodeMirror-info input::placeholder, .CodeMirror-lint-tooltip input::placeholder, .graphiql-dialog input::placeholder {
      color: hsla(var(--color-neutral), var(--alpha-secondary));
    }

.graphiql-container a, .CodeMirror-info a, .CodeMirror-lint-tooltip a, .graphiql-dialog a {
    color: hsl(var(--color-primary))
  }

.graphiql-container a:focus, .CodeMirror-info a:focus, .CodeMirror-lint-tooltip a:focus, .graphiql-dialog a:focus {
      outline: hsl(var(--color-primary)) auto 1px;
    }
.graphiql-un-styled,
button.graphiql-un-styled {
  all: unset;
  border-radius: var(--border-radius-4);
  cursor: pointer
}

:is(.graphiql-un-styled,button.graphiql-un-styled):hover {
    background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  }

:is(.graphiql-un-styled,button.graphiql-un-styled):active {
    background-color: hsla(
      var(--color-neutral),
      var(--alpha-background-medium)
    );
  }

:is(.graphiql-un-styled,button.graphiql-un-styled):focus {
    outline: hsla(var(--color-neutral), var(--alpha-background-heavy)) auto 1px;
  }

.graphiql-button,
button.graphiql-button {
  background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  border: none;
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-neutral));
  cursor: pointer;
  font-size: var(--font-size-body);
  padding: var(--px-8) var(--px-12)
}

:is(.graphiql-button,button.graphiql-button):hover,
  :is(.graphiql-button,button.graphiql-button):active {
    background-color: hsla(
      var(--color-neutral),
      var(--alpha-background-medium)
    );
  }

:is(.graphiql-button,button.graphiql-button):focus {
    outline: hsla(var(--color-neutral), var(--alpha-background-heavy)) auto 1px;
  }

.graphiql-button-success:is(.graphiql-button,button.graphiql-button) {
    background-color: hsla(var(--color-success), var(--alpha-background-heavy));
  }

.graphiql-button-error:is(.graphiql-button,button.graphiql-button) {
    background-color: hsla(var(--color-error), var(--alpha-background-heavy));
  }
.graphiql-button-group {
  background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  /* Border radius of button plus padding */
  border-radius: calc(var(--border-radius-4) + var(--px-4));
  display: flex;
  padding: var(--px-4)
}
.graphiql-button-group > button.graphiql-button {
    background-color: transparent
  }
.graphiql-button-group > button.graphiql-button:hover {
      background-color: hsla(
        var(--color-neutral),
        var(--alpha-background-light)
      );
    }
.graphiql-button-group > button.graphiql-button.active {
      background-color: hsl(var(--color-base));
      cursor: default;
    }
.graphiql-button-group > * + * {
    margin-left: var(--px-8);
  }
.graphiql-dialog-overlay {
  position: fixed;
  inset: 0;
  background-color: hsla(var(--color-neutral), var(--alpha-background-heavy));
  /**
   * CodeMirror has a `z-index` set for the container of the scrollbar of the
   * editor, so we have to add one here to make sure that the dialog is shown
   * above the editor scrollbar (if they are visible).
   */
  z-index: 10;
}

.graphiql-dialog {
  background-color: hsl(var(--color-base));
  border: var(--popover-border);
  border-radius: var(--border-radius-12);
  box-shadow: var(--popover-box-shadow);
  margin: 0;
  max-height: 80vh;
  max-width: 80vw;
  overflow: auto;
  padding: 0;
  width: unset;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: fixed;
  z-index: 10;
}

.graphiql-dialog-close > svg {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: block;
  height: var(--px-12);
  padding: var(--px-12);
  width: var(--px-12);
}
.graphiql-dropdown-content {
  background-color: hsl(var(--color-base));
  border: var(--popover-border);
  border-radius: var(--border-radius-8);
  box-shadow: var(--popover-box-shadow);
  font-size: inherit;
  max-width: 250px;
  padding: var(--px-4);
  font-family: var(--font-family);
  color: hsl(var(--color-neutral));
  max-height: min(
    calc(var(--radix-dropdown-menu-content-available-height) - 10px),
    400px
  );
  overflow-y: scroll;
}

.graphiql-dropdown-item {
  border-radius: var(--border-radius-4);
  font-size: inherit;
  margin: var(--px-4);
  overflow: hidden;
  padding: var(--px-6) var(--px-8);
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  line-height: var(--line-height)
}

.graphiql-dropdown-item[data-selected],
  .graphiql-dropdown-item[data-current-nav],
  .graphiql-dropdown-item:hover {
    background-color: hsla(var(--color-neutral), var(--alpha-background-light));
    color: inherit;
  }

.graphiql-dropdown-item:not(:first-child) {
    margin-top: 0;
  }
/**
 * We render markdown in the following places:
 * - In the hint tooltip when typing in the query editor (field description
 *   and, optionally, deprecation reason).
 * - In the info tooltip when hovering over a field in the query editor
 *   (field description and, optionally, deprecation reason).
 */

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) blockquote {
    margin-left: 0;
    margin-right: 0;
    padding-left: var(--px-8);
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) code,
  :is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) pre {
    border-radius: var(--border-radius-4);
    font-family: var(--font-family-mono);
    font-size: var(--font-size-inline-code);
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) code {
    padding: var(--px-2);
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) pre {
    overflow: auto;
    padding: var(--px-6) var(--px-8);
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) pre code {
    background-color: initial;
    border-radius: 0;
    padding: 0;
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) ol,
  :is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) ul {
    padding-left: var(--px-16);
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) ol {
    list-style-type: decimal;
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) ul {
    list-style-type: disc;
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) img {
    border-radius: var(--border-radius-4);
    max-height: 120px;
    max-width: 100%;
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) > :first-child {
    margin-top: 0;
  }

:is(.graphiql-markdown-description,.graphiql-markdown-deprecation,.CodeMirror-hint-information-description,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-description,.CodeMirror-info .info-deprecation) > :last-child {
    margin-bottom: 0;
  }

:is(.graphiql-markdown-description,.CodeMirror-hint-information-description,.CodeMirror-info .info-description) a {
    color: hsl(var(--color-primary));
    text-decoration: none
  }

:is(.graphiql-markdown-description,.CodeMirror-hint-information-description,.CodeMirror-info .info-description) a:hover {
      text-decoration: underline;
    }

:is(.graphiql-markdown-description,.CodeMirror-hint-information-description,.CodeMirror-info .info-description) blockquote {
    border-left: 1.5px solid hsla(var(--color-neutral), var(--alpha-tertiary));
  }

:is(.graphiql-markdown-description,.CodeMirror-hint-information-description,.CodeMirror-info .info-description) code,
  :is(.graphiql-markdown-description,.CodeMirror-hint-information-description,.CodeMirror-info .info-description) pre {
    background-color: hsla(var(--color-neutral), var(--alpha-background-light));
    color: hsl(var(--color-neutral));
  }

:is(.graphiql-markdown-description,.CodeMirror-hint-information-description,.CodeMirror-info .info-description) > * {
    margin: var(--px-12) 0;
  }

:is(.graphiql-markdown-deprecation,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-deprecation) a {
    color: hsl(var(--color-warning));
    text-decoration: underline;
  }

:is(.graphiql-markdown-deprecation,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-deprecation) blockquote {
    border-left: 1.5px solid hsl(var(--color-warning));
  }

:is(.graphiql-markdown-deprecation,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-deprecation) code,
  :is(.graphiql-markdown-deprecation,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-deprecation) pre {
    background-color: hsla(var(--color-warning), var(--alpha-background-heavy));
  }

:is(.graphiql-markdown-deprecation,.CodeMirror-hint-information-deprecation-reason,.CodeMirror-info .info-deprecation) > * {
    margin: var(--px-8) 0;
  }

.graphiql-markdown-preview > :not(:first-child) {
  display: none;
}

/**
 * We show deprecations in the following places:
 * - In the hint tooltip when typing in the query editor.
 * - In the info tooltip when hovering over a field in the query editor.
 */

.CodeMirror-hint-information-deprecation,
.CodeMirror-info .info-deprecation {
  background-color: hsla(var(--color-warning), var(--alpha-background-light));
  border: 1px solid hsl(var(--color-warning));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-warning));
  margin-top: var(--px-12);
  padding: var(--px-6) var(--px-8);
}

.CodeMirror-hint-information-deprecation-label,
.CodeMirror-info .info-deprecation-label {
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
}

.CodeMirror-hint-information-deprecation-reason,
.CodeMirror-info .info-deprecation-reason {
  margin-top: var(--px-6);
}
.graphiql-spinner {
  height: 56px;
  margin: auto;
  margin-top: var(--px-16);
  width: 56px
}

.graphiql-spinner::after {
    animation: rotation 0.8s linear 0s infinite;
    border: 4px solid transparent;
    border-radius: 100%;
    border-top: 4px solid hsla(var(--color-neutral), var(--alpha-tertiary));
    content: '';
    display: inline-block;
    height: 46px;
    vertical-align: middle;
    width: 46px;
  }

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.graphiql-tooltip {
  background: hsl(var(--color-base));
  border: var(--popover-border);
  border-radius: var(--border-radius-4);
  box-shadow: var(--popover-box-shadow);
  color: hsl(var(--color-neutral));
  font-size: inherit;
  padding: var(--px-4) var(--px-6);
  font-family: var(--font-family);
}
.graphiql-tabs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: var(--px-12)
}

.graphiql-tabs > :not(:first-child) {
    margin-left: var(--px-12);
  }

.graphiql-tab {
  align-items: stretch;
  border-radius: var(--border-radius-8);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: flex
}

.graphiql-tab > button.graphiql-tab-close {
    visibility: hidden;
  }

.graphiql-tab.graphiql-tab-active > button.graphiql-tab-close,
  .graphiql-tab:hover > button.graphiql-tab-close,
  .graphiql-tab:focus-within > button.graphiql-tab-close {
    visibility: unset;
  }

.graphiql-tab.graphiql-tab-active {
    background-color: hsla(var(--color-neutral), var(--alpha-background-heavy));
    color: hsla(var(--color-neutral), 1);
  }

button.graphiql-tab-button {
  padding: var(--px-4) 0 var(--px-4) var(--px-8);
}

button.graphiql-tab-close {
  align-items: center;
  display: flex;
  padding: var(--px-4) var(--px-8)
}

button.graphiql-tab-close > svg {
    height: var(--px-8);
    width: var(--px-8);
  }
.graphiql-history-header {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-medium);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.graphiql-history-header button {
  font-size: var(--font-size-inline-code);
  padding: var(--px-6) var(--px-10);
}

.graphiql-history-items {
  margin: var(--px-16) 0 0;
  list-style: none;
  padding: 0;
}

.graphiql-history-item {
  border-radius: var(--border-radius-4);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: flex;
  font-size: var(--font-size-inline-code);
  font-family: var(--font-family-mono);
  height: 34px
}

.graphiql-history-item:hover {
    color: hsl(var(--color-neutral));
    background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  }

.graphiql-history-item:not(:first-child) {
    margin-top: var(--px-4);
  }

.graphiql-history-item.editable {
    background-color: hsla(
      var(--color-primary),
      var(--alpha-background-medium)
    )
  }

.graphiql-history-item.editable > input {
      background: transparent;
      border: none;
      flex: 1;
      margin: 0;
      outline: none;
      padding: 0 var(--px-10);
      width: 100%
    }

.graphiql-history-item.editable > input::placeholder {
        color: hsla(var(--color-neutral), var(--alpha-secondary));
      }

.graphiql-history-item.editable > button {
      color: hsl(var(--color-primary));
      padding: 0 var(--px-10)
    }

.graphiql-history-item.editable > button:active {
        background-color: hsla(
          var(--color-primary),
          var(--alpha-background-heavy)
        );
      }

.graphiql-history-item.editable > button:focus {
        outline: hsl(var(--color-primary)) auto 1px;
      }

.graphiql-history-item.editable > button > svg {
        display: block;
      }

button.graphiql-history-item-label {
  flex: 1;
  padding: var(--px-8) var(--px-10);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

button.graphiql-history-item-action {
  align-items: center;
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: flex;
  padding: var(--px-8) var(--px-6)
}

button.graphiql-history-item-action:hover {
    color: hsl(var(--color-neutral));
  }

button.graphiql-history-item-action > svg {
    height: 14px;
    width: 14px;
  }

.graphiql-history-item-spacer {
  height: var(--px-16);
}
.graphiql-doc-explorer-default-value {
  color: hsl(var(--color-success));
}
a.graphiql-doc-explorer-type-name {
  color: hsl(var(--color-warning));
  text-decoration: none
}
a.graphiql-doc-explorer-type-name:hover {
    text-decoration: underline;
  }
a.graphiql-doc-explorer-type-name:focus {
    outline: hsl(var(--color-warning)) auto 1px;
  }

  .graphiql-doc-explorer-argument > * + * {
    margin-top: var(--px-12);
  }

.graphiql-doc-explorer-argument-name {
  color: hsl(var(--color-secondary));
}

.graphiql-doc-explorer-argument-deprecation {
  background-color: hsla(var(--color-warning), var(--alpha-background-light));
  border: 1px solid hsl(var(--color-warning));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-warning));
  padding: var(--px-8);
}

.graphiql-doc-explorer-argument-deprecation-label {
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
}
.graphiql-doc-explorer-deprecation {
  background-color: hsla(var(--color-warning), var(--alpha-background-light));
  border: 1px solid hsl(var(--color-warning));
  border-radius: var(--px-4);
  color: hsl(var(--color-warning));
  padding: var(--px-8);
}

.graphiql-doc-explorer-deprecation-label {
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
}
.graphiql-doc-explorer-directive {
  color: hsl(var(--color-secondary));
}
.graphiql-doc-explorer-section-title {
  align-items: center;
  display: flex;
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
  line-height: 1
}

.graphiql-doc-explorer-section-title > svg {
    height: var(--px-16);
    margin-right: var(--px-8);
    width: var(--px-16);
  }

.graphiql-doc-explorer-section-content {
  margin-left: var(--px-8);
  margin-top: var(--px-16)
}

.graphiql-doc-explorer-section-content > * + * {
    margin-top: var(--px-16);
  }
.graphiql-doc-explorer-root-type {
  color: hsl(var(--color-info));
}
.graphiql-doc-explorer-search {
  color: hsla(var(--color-neutral), var(--alpha-secondary))
}

.graphiql-doc-explorer-search:not([data-state='idle']) {
    border: var(--popover-border);
    border-radius: var(--border-radius-4);
    box-shadow: var(--popover-box-shadow);
    color: hsl(var(--color-neutral))
  }

.graphiql-doc-explorer-search:not([data-state='idle']) .graphiql-doc-explorer-search-input {
      background: hsl(var(--color-base));
    }

.graphiql-doc-explorer-search-input {
  align-items: center;
  background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  border-radius: var(--border-radius-4);
  display: flex;
  padding: var(--px-8) var(--px-12);
}

.graphiql-doc-explorer-search [role='combobox'] {
  border: none;
  background-color: transparent;
  margin-left: var(--px-4);
  width: 100%
}

.graphiql-doc-explorer-search [role='combobox']:focus {
    outline: none;
  }

.graphiql-doc-explorer-search [role='listbox'] {
  background-color: hsl(var(--color-base));
  border: none;
  border-bottom-left-radius: var(--border-radius-4);
  border-bottom-right-radius: var(--border-radius-4);
  border-top: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  font-size: var(--font-size-body);
  padding: var(--px-4);
  /**
   * This makes sure that the logic for auto-scrolling the search results when
   * using keyboard navigation works properly (we use `offsetTop` there).
   */
  position: relative;
}

.graphiql-doc-explorer-search [role='option'] {
  border-radius: var(--border-radius-4);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  overflow-x: hidden;
  padding: var(--px-8) var(--px-12);
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer
}

.graphiql-doc-explorer-search [role='option'][data-headlessui-state='active'] {
    background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  }

.graphiql-doc-explorer-search [role='option']:hover {
    background-color: hsla(
      var(--color-neutral),
      var(--alpha-background-medium)
    );
  }

.graphiql-doc-explorer-search [role='option'][data-headlessui-state='active']:hover {
    background-color: hsla(var(--color-neutral), var(--alpha-background-heavy));
  }

:is(.graphiql-doc-explorer-search [role='option']) + :is(.graphiql-doc-explorer-search [role='option']) {
    margin-top: var(--px-4);
  }

.graphiql-doc-explorer-search-type {
  color: hsl(var(--color-info));
}

.graphiql-doc-explorer-search-field {
  color: hsl(var(--color-warning));
}

.graphiql-doc-explorer-search-argument {
  color: hsl(var(--color-secondary));
}

.graphiql-doc-explorer-search-divider {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
  margin-top: var(--px-8);
  padding: var(--px-8) var(--px-12);
}

.graphiql-doc-explorer-search-empty {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  padding: var(--px-8) var(--px-12);
}
a.graphiql-doc-explorer-field-name {
  color: hsl(var(--color-info));
  text-decoration: none
}
a.graphiql-doc-explorer-field-name:hover {
    text-decoration: underline;
  }
a.graphiql-doc-explorer-field-name:focus {
    outline: hsl(var(--color-info)) auto 1px;
  }
.graphiql-doc-explorer-item > :not(:first-child) {
  margin-top: var(--px-12);
}

.graphiql-doc-explorer-argument-multiple {
  margin-left: var(--px-8);
}

.graphiql-doc-explorer-enum-value {
  color: hsl(var(--color-info));
}
/* The header of the doc explorer */
.graphiql-doc-explorer-header {
  display: flex;
  justify-content: space-between;
  position: relative
}
.graphiql-doc-explorer-header:focus-within .graphiql-doc-explorer-title {
      /* Hide the header when focussing the search input */
      visibility: hidden;
    }
.graphiql-doc-explorer-header:focus-within .graphiql-doc-explorer-back:not(:focus) {
      /**
        * Make the back link invisible when focussing the search input. Hiding
        * it in any other way makes it impossible to focus the link by pressing
        * Shift-Tab while the input is focussed.
        */
      color: transparent;
    }
.graphiql-doc-explorer-header-content {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

/* The search input in the header of the doc explorer */
.graphiql-doc-explorer-search {
  position: absolute;
  right: 0;
  top: 0
}
.graphiql-doc-explorer-search:focus-within {
    left: 0;
  }
.graphiql-doc-explorer-search:not(:focus-within) [role='combobox'] {
    height: 24px;
    width: 5ch;
  }
.graphiql-doc-explorer-search [role='combobox']:focus {
    width: 100%;
  }

/* The back-button in the doc explorer */
a.graphiql-doc-explorer-back {
  align-items: center;
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: flex;
  text-decoration: none
}
a.graphiql-doc-explorer-back:hover {
    text-decoration: underline;
  }
a.graphiql-doc-explorer-back:focus {
    outline: hsla(var(--color-neutral), var(--alpha-secondary)) auto 1px
  }
a.graphiql-doc-explorer-back:focus + .graphiql-doc-explorer-title {
      /* Don't hide the header when focussing the back link */
      visibility: unset;
    }
a.graphiql-doc-explorer-back > svg {
    height: var(--px-8);
    margin-right: var(--px-8);
    width: var(--px-8);
  }

/* The title of the currently active page in the doc explorer */
.graphiql-doc-explorer-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-h2);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.graphiql-doc-explorer-title:not(:first-child) {
    font-size: var(--font-size-h3);
    margin-top: var(--px-8);
  }

/* The contents of the currently active page in the doc explorer */
.graphiql-doc-explorer-content > * {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  margin-top: var(--px-20);
}

/* Error message */
.graphiql-doc-explorer-error {
  background-color: hsla(var(--color-error), var(--alpha-background-heavy));
  border: 1px solid hsl(var(--color-error));
  border-radius: var(--border-radius-8);
  color: hsl(var(--color-error));
  padding: var(--px-8) var(--px-12);
}
/* BASICS */

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}

.CodeMirror-linenumbers {}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker { color: black; }

.CodeMirror-guttermarker-subtle { color: #999; }

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}

/* Shown when moving in bi-directional text */

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line > span::selection, 
.cm-fat-cursor .CodeMirror-line > span > span::selection { background: transparent; }

.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection { background: transparent; }

.cm-fat-cursor { caret-color: transparent; }

@-moz-keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}

@-webkit-keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}

@keyframes blink {
  0% {}
  50% { background-color: transparent; }
  100% {}
}

/* Can style cursor different in overwrite (non-insert) mode */

.CodeMirror-overwrite .CodeMirror-cursor {}

.cm-tab { display: inline-block; text-decoration: inherit; }

.CodeMirror-rulers {
  position: absolute;
  left: 0; right: 0; top: -50px; bottom: 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0; bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {color: blue;}

.cm-s-default .cm-quote {color: #090;}

.cm-negative {color: #d44;}

.cm-positive {color: #292;}

.cm-header, .cm-strong {font-weight: bold;}

.cm-em {font-style: italic;}

.cm-link {text-decoration: underline;}

.cm-strikethrough {text-decoration: line-through;}

.cm-s-default .cm-keyword {color: #708;}

.cm-s-default .cm-atom {color: #219;}

.cm-s-default .cm-number {color: #164;}

.cm-s-default .cm-def {color: #00f;}

.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {}

.cm-s-default .cm-variable-2 {color: #05a;}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {color: #085;}

.cm-s-default .cm-comment {color: #a50;}

.cm-s-default .cm-string {color: #a11;}

.cm-s-default .cm-string-2 {color: #f50;}

.cm-s-default .cm-meta {color: #555;}

.cm-s-default .cm-qualifier {color: #555;}

.cm-s-default .cm-builtin {color: #30a;}

.cm-s-default .cm-bracket {color: #997;}

.cm-s-default .cm-tag {color: #170;}

.cm-s-default .cm-attribute {color: #00c;}

.cm-s-default .cm-hr {color: #999;}

.cm-s-default .cm-link {color: #00c;}

.cm-s-default .cm-error {color: #f00;}

.cm-invalidchar {color: #f00;}

.CodeMirror-composing { border-bottom: 2px solid; }

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {color: #0b0;}

div.CodeMirror span.CodeMirror-nonmatchingbracket {color: #a22;}

.CodeMirror-matchingtag { background: rgba(255, 150, 0, .3); }

.CodeMirror-activeline-background {background: #e8f2ff;}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px; margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
  z-index: 0;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */

.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}

.CodeMirror-vscrollbar {
  right: 0; top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0; left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0; bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0; bottom: 0;
}

.CodeMirror-gutters {
  position: absolute; left: 0; top: 0;
  min-height: 100%;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  position: absolute;
  top: 0; bottom: 0;
  z-index: 4;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper ::selection { background-color: transparent }

.CodeMirror-gutter-wrapper ::-moz-selection { background-color: transparent }

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {}

.CodeMirror-rtl pre { direction: rtl; }

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */

.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}

.CodeMirror-measure pre { position: static; }

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected { background: #d9d9d9; }

.CodeMirror-focused .CodeMirror-selected { background: #d7d4f0; }

.CodeMirror-crosshair { cursor: crosshair; }

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: #d7d4f0; }

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0; }

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, .4);
}

/* Used to force a border model for a node */

.cm-force-border { padding-right: .1px; }

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */

.cm-tab-wrap-hack:after { content: ''; }

/* Help users use markselection to safely style text background */

span.CodeMirror-selectedtext { background: none; }

/* Make the editors fill up their container and make them scrollable */

.graphiql-container .CodeMirror {
  height: 100%;
  position: absolute;
  width: 100%;
}

/* Override font settings */

.graphiql-container .CodeMirror {
  font-family: var(--font-family-mono);
}

/* Set default background color */

.graphiql-container .CodeMirror,
.graphiql-container .CodeMirror-gutters {
  background: none;
  background-color: var(--editor-background, hsl(var(--color-base)));
}

/* No padding around line numbers */

.graphiql-container .CodeMirror-linenumber {
  padding: 0;
}

/* No border between gutter and editor */

.graphiql-container .CodeMirror-gutters {
  border: none;
}

/**
 * Editor theme
 */

.cm-s-graphiql {
  /* Default to punctuation */
  color: hsla(var(--color-neutral), var(--alpha-tertiary))

  /* OperationType, `fragment`, `on` */
}

.cm-s-graphiql .cm-keyword {
    color: hsl(var(--color-primary));
  }

/* Name (OperationDefinition), FragmentName */

.cm-s-graphiql .cm-def {
    color: hsl(var(--color-tertiary));
  }

/* Punctuator (except `$` and `@`) */

.cm-s-graphiql .cm-punctuation {
    color: hsla(var(--color-neutral), var(--alpha-tertiary));
  }

/* Variable */

.cm-s-graphiql .cm-variable {
    color: hsl(var(--color-secondary));
  }

/* NamedType */

.cm-s-graphiql .cm-atom {
    color: hsl(var(--color-tertiary));
  }

/* IntValue, FloatValue */

.cm-s-graphiql .cm-number {
    color: hsl(var(--color-success));
  }

/* StringValue */

.cm-s-graphiql .cm-string {
    color: hsl(var(--color-warning));
  }

/* BooleanValue */

.cm-s-graphiql .cm-builtin {
    color: hsl(var(--color-success));
  }

/* EnumValue */

.cm-s-graphiql .cm-string-2 {
    color: hsl(var(--color-secondary));
  }

/* Name (ObjectField, Argument) */

.cm-s-graphiql .cm-attribute {
    color: hsl(var(--color-tertiary));
  }

/* Name (Directive) */

.cm-s-graphiql .cm-meta {
    color: hsl(var(--color-tertiary));
  }

/* Name (Alias, Field without Alias) */

.cm-s-graphiql .cm-property {
    color: hsl(var(--color-info));
  }

/* Name (Field with Alias) */

.cm-s-graphiql .cm-qualifier {
    color: hsl(var(--color-secondary));
  }

/* Comment */

.cm-s-graphiql .cm-comment {
    color: hsla(var(--color-neutral), var(--alpha-secondary));
  }

/* Whitespace */

.cm-s-graphiql .cm-ws {
    color: hsla(var(--color-neutral), var(--alpha-tertiary));
  }

/* Invalid characters */

.cm-s-graphiql .cm-invalidchar {
    color: hsl(var(--color-error));
  }

/* Cursor */

.cm-s-graphiql .CodeMirror-cursor {
    border-left: 2px solid hsla(var(--color-neutral), var(--alpha-secondary));
  }

/* Color for line numbers and fold-gutters */

.cm-s-graphiql .CodeMirror-linenumber {
    color: hsla(var(--color-neutral), var(--alpha-tertiary));
  }

/* Matching bracket colors */

.graphiql-container div.CodeMirror span.CodeMirror-matchingbracket,
.graphiql-container div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: hsl(var(--color-warning));
}

/* Selected text blocks */

.graphiql-container .CodeMirror-selected,
.graphiql-container .CodeMirror-focused .CodeMirror-selected {
  background: hsla(var(--color-neutral), var(--alpha-background-heavy));
}

/* Position the search dialog */

.graphiql-container .CodeMirror-dialog {
  background: inherit;
  color: inherit;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: var(--px-2) var(--px-6);
  position: absolute;
  z-index: 6;
}

.graphiql-container .CodeMirror-dialog-top {
  border-bottom: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  padding-bottom: var(--px-12);
  top: 0;
}

.graphiql-container .CodeMirror-dialog-bottom {
  border-top: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  bottom: 0;
  padding-top: var(--px-12);
}

/* Hide the search hint */

.graphiql-container .CodeMirror-search-hint {
  display: none;
}

/* Style the input field for searching */

.graphiql-container .CodeMirror-dialog input {
  border: 1px solid hsla(var(--color-neutral), var(--alpha-background-heavy));
  border-radius: var(--border-radius-4);
  padding: var(--px-4);
}

.graphiql-container .CodeMirror-dialog input:focus {
  outline: hsl(var(--color-primary)) solid 2px;
}

/* Set the highlight color for search results */

.graphiql-container .cm-searching {
  background-color: hsla(var(--color-warning), var(--alpha-background-light));
  /**
   * When cycling through search results, CodeMirror overlays the current 
   * selection with another element that has the .CodeMirror-selected class
   * applied. This adds another background color (see above), but this extra
   * box does not quite match the height of this element. To match them up we
   * add some extra padding here. (Note that this doesn't affect the line
   * height of the CodeMirror editor as all line wrappers have a fixed height.)
   */
  padding-bottom: 1.5px;
  padding-top: 0.5px;
}
.CodeMirror-foldmarker {
  color: blue;
  text-shadow: #b9f 1px 1px 2px, #b9f -1px -1px 2px, #b9f 1px -1px 2px, #b9f -1px 1px 2px;
  font-family: arial;
  line-height: .3;
  cursor: pointer;
}
.CodeMirror-foldgutter {
  width: .7em;
}
.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
  cursor: pointer;
}
.CodeMirror-foldgutter-open:after {
  content: "\25BE";
}
.CodeMirror-foldgutter-folded:after {
  content: "\25B8";
}
.CodeMirror-foldgutter {
  width: var(--px-12);
}
.CodeMirror-foldmarker {
  background-color: hsl(var(--color-info));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-base));
  font-family: inherit;
  margin: 0 var(--px-4);
  padding: 0 var(--px-8);
  text-shadow: none;
}
.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
  color: hsla(var(--color-neutral), var(--alpha-tertiary))
}
.CodeMirror-foldgutter-open::after, .CodeMirror-foldgutter-folded::after {
    margin: 0 var(--px-2);
  }
.graphiql-editor {
  height: 100%;
  position: relative;
  width: 100%
}
.graphiql-editor.hidden {
    /* Just setting `display: none;` would break the editor gutters */
    left: -9999px;
    position: absolute;
    top: -9999px;
    visibility: hidden;
  }
/* The lint marker gutter */
.CodeMirror-lint-markers {
  width: 16px;
}
.CodeMirror-lint-tooltip {
  background-color: #ffd;
  border: 1px solid black;
  border-radius: 4px 4px 4px 4px;
  color: black;
  font-family: monospace;
  font-size: 10pt;
  overflow: hidden;
  padding: 2px 5px;
  position: fixed;
  white-space: pre;
  white-space: pre-wrap;
  z-index: 100;
  max-width: 600px;
  opacity: 0;
  transition: opacity .4s;
  -moz-transition: opacity .4s;
  -webkit-transition: opacity .4s;
  -o-transition: opacity .4s;
  -ms-transition: opacity .4s;
}
.CodeMirror-lint-mark {
  background-position: left bottom;
  background-repeat: repeat-x;
}
.CodeMirror-lint-mark-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJFhQXEbhTg7YAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAMklEQVQI12NkgIIvJ3QXMjAwdDN+OaEbysDA4MPAwNDNwMCwiOHLCd1zX07o6kBVGQEAKBANtobskNMAAAAASUVORK5CYII=");
}
.CodeMirror-lint-mark-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJDw4cOCW1/KIAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAHElEQVQI12NggIL/DAz/GdA5/xkY/qPKMDAwAADLZwf5rvm+LQAAAABJRU5ErkJggg==");
}
.CodeMirror-lint-marker {
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  position: relative;
}
.CodeMirror-lint-message {
  padding-left: 18px;
  background-position: top left;
  background-repeat: no-repeat;
}
.CodeMirror-lint-marker-warning, .CodeMirror-lint-message-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAANlBMVEX/uwDvrwD/uwD/uwD/uwD/uwD/uwD/uwD/uwD6twD/uwAAAADurwD2tQD7uAD+ugAAAAD/uwDhmeTRAAAADHRSTlMJ8mN1EYcbmiixgACm7WbuAAAAVklEQVR42n3PUQqAIBBFUU1LLc3u/jdbOJoW1P08DA9Gba8+YWJ6gNJoNYIBzAA2chBth5kLmG9YUoG0NHAUwFXwO9LuBQL1giCQb8gC9Oro2vp5rncCIY8L8uEx5ZkAAAAASUVORK5CYII=");
}
.CodeMirror-lint-marker-error, .CodeMirror-lint-message-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAHlBMVEW7AAC7AACxAAC7AAC7AAAAAAC4AAC5AAD///+7AAAUdclpAAAABnRSTlMXnORSiwCK0ZKSAAAATUlEQVR42mWPOQ7AQAgDuQLx/z8csYRmPRIFIwRGnosRrpamvkKi0FTIiMASR3hhKW+hAN6/tIWhu9PDWiTGNEkTtIOucA5Oyr9ckPgAWm0GPBog6v4AAAAASUVORK5CYII=");
}
.CodeMirror-lint-marker-multiple {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAMAAADzjKfhAAAACVBMVEUAAAAAAAC/v7914kyHAAAAAXRSTlMAQObYZgAAACNJREFUeNo1ioEJAAAIwmz/H90iFFSGJgFMe3gaLZ0od+9/AQZ0ADosbYraAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%; height: 100%;
}
.CodeMirror-lint-line-error {
  background-color: rgba(183, 76, 81, 0.08);
}
.CodeMirror-lint-line-warning {
  background-color: rgba(255, 211, 0, 0.1);
}
/* Text styles */
.CodeMirror-lint-mark-error,
.CodeMirror-lint-mark-warning {
  background-repeat: repeat-x;
  /**
   * The following two are very specific to the font size, so we use 
   * "magic values" instead of variables.
   */
  background-size: 10px 3px;
  background-position: 0 95%;
}
.cm-s-graphiql .CodeMirror-lint-mark-error {
  color: hsl(var(--color-error));
}
.CodeMirror-lint-mark-error {
  background-image: linear-gradient(
      45deg,
      transparent 65%,
      hsl(var(--color-error)) 80%,
      transparent 90%
    ),
    linear-gradient(
      135deg,
      transparent 5%,
      hsl(var(--color-error)) 15%,
      transparent 25%
    ),
    linear-gradient(
      135deg,
      transparent 45%,
      hsl(var(--color-error)) 55%,
      transparent 65%
    ),
    linear-gradient(
      45deg,
      transparent 25%,
      hsl(var(--color-error)) 35%,
      transparent 50%
    );
}
.cm-s-graphiql .CodeMirror-lint-mark-warning {
  color: hsl(var(--color-warning));
}
.CodeMirror-lint-mark-warning {
  background-image: linear-gradient(
      45deg,
      transparent 65%,
      hsl(var(--color-warning)) 80%,
      transparent 90%
    ),
    linear-gradient(
      135deg,
      transparent 5%,
      hsl(var(--color-warning)) 15%,
      transparent 25%
    ),
    linear-gradient(
      135deg,
      transparent 45%,
      hsl(var(--color-warning)) 55%,
      transparent 65%
    ),
    linear-gradient(
      45deg,
      transparent 25%,
      hsl(var(--color-warning)) 35%,
      transparent 50%
    );
}
/* Popup styles */
.CodeMirror-lint-tooltip {
  background-color: hsl(var(--color-base));
  border: var(--popover-border);
  border-radius: var(--border-radius-8);
  box-shadow: var(--popover-box-shadow);
  font-size: var(--font-size-body);
  font-family: var(--font-family);
  max-width: 600px;
  overflow: hidden;
  padding: var(--px-12);
}
.CodeMirror-lint-message-error,
.CodeMirror-lint-message-warning {
  background-image: none;
  padding: 0;
}
.CodeMirror-lint-message-error {
  color: hsl(var(--color-error));
}
.CodeMirror-lint-message-warning {
  color: hsl(var(--color-warning));
}
.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;

  margin: 0;
  padding: 2px;

  -webkit-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
  -moz-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
  box-shadow: 2px 3px 5px rgba(0,0,0,.2);
  border-radius: 3px;
  border: 1px solid silver;

  background: white;
  font-size: 90%;
  font-family: monospace;

  max-height: 20em;
  overflow-y: auto;
}

.CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  border-radius: 2px;
  white-space: pre;
  color: black;
  cursor: pointer;
}

li.CodeMirror-hint-active {
  background: #08f;
  color: white;
}

/* Popup styles */

.CodeMirror-hints {
  background: hsl(var(--color-base));
  border: var(--popover-border);
  border-radius: var(--border-radius-8);
  box-shadow: var(--popover-box-shadow);
  display: grid;
  font-family: var(--font-family);
  font-size: var(--font-size-body);
  grid-template-columns: auto fit-content(300px);
  /* By default this is equals exactly 8 items including margins */
  max-height: 264px;
  padding: 0;
}

/* Autocomplete items */

.CodeMirror-hint {
  border-radius: var(--border-radius-4);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  grid-column: 1 / 2;
  margin: var(--px-4);
  /* Override element style added by codemirror */
  padding: var(--px-6) var(--px-8) !important
}

.CodeMirror-hint:not(:first-child) {
    margin-top: 0;
  }

li.CodeMirror-hint-active {
  background: hsla(var(--color-primary), var(--alpha-background-medium));
  color: hsl(var(--color-primary));
}

/* Sidebar with additional information */

.CodeMirror-hint-information {
  border-left: 1px solid
    hsla(var(--color-neutral), var(--alpha-background-heavy));
  grid-column: 2 / 3;
  grid-row: 1 / 99999;
  /* Same as the popup */
  max-height: 264px;
  overflow: auto;
  padding: var(--px-12);
}

.CodeMirror-hint-information-header {
  display: flex;
  align-items: baseline;
}

.CodeMirror-hint-information-field-name {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-medium);
}

.CodeMirror-hint-information-type-name-pill {
  border: 1px solid hsla(var(--color-neutral), var(--alpha-tertiary));
  border-radius: var(--border-radius-4);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  margin-left: var(--px-6);
  padding: var(--px-4);
}

.CodeMirror-hint-information-type-name {
  color: inherit;
  text-decoration: none
}

.CodeMirror-hint-information-type-name:hover {
    text-decoration: underline dotted;
  }

.CodeMirror-hint-information-description {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  margin-top: var(--px-12);
}
/* Popup styles */
.CodeMirror-info {
  background-color: hsl(var(--color-base));
  border: var(--popover-border);
  border-radius: var(--border-radius-8);
  box-shadow: var(--popover-box-shadow);
  color: hsl(var(--color-neutral));
  max-height: 300px;
  max-width: 400px;
  opacity: 0;
  overflow: auto;
  padding: var(--px-12);
  position: fixed;
  transition: opacity 0.15s;
  z-index: 10

  /* Link styles */
}
.CodeMirror-info a {
    color: inherit;
    text-decoration: none
  }
.CodeMirror-info a:hover {
      text-decoration: underline dotted;
    }
/* Align elements in header */
.CodeMirror-info .CodeMirror-info-header {
    display: flex;
    align-items: baseline;
  }
/* Main elements */
.CodeMirror-info .CodeMirror-info-header > .type-name,
    .CodeMirror-info .CodeMirror-info-header > .field-name,
    .CodeMirror-info .CodeMirror-info-header > .arg-name,
    .CodeMirror-info .CodeMirror-info-header > .directive-name,
    .CodeMirror-info .CodeMirror-info-header > .enum-value {
      font-size: var(--font-size-h4);
      font-weight: var(--font-weight-medium);
    }
/* Type names */
.CodeMirror-info .type-name-pill {
    border: 1px solid hsla(var(--color-neutral), var(--alpha-tertiary));
    border-radius: var(--border-radius-4);
    color: hsla(var(--color-neutral), var(--alpha-secondary));
    margin-left: var(--px-6);
    padding: var(--px-4);
  }
/* Descriptions */
.CodeMirror-info .info-description {
    color: hsla(var(--color-neutral), var(--alpha-secondary));
    margin-top: var(--px-12);
    overflow: hidden;
  }
/* Underline the clickable token */
.CodeMirror-jump-token {
  text-decoration: underline dotted;
  cursor: pointer;
}
.auto-inserted-leaf.cm-property {
  animation-duration: 6s;
  animation-name: insertionFade;
  border-radius: var(--border-radius-4);
  padding: var(--px-2);
}

@keyframes insertionFade {
  from,
  to {
    background-color: none;
  }

  15%,
  85% {
    background-color: hsla(var(--color-warning), var(--alpha-background-light));
  }
}
button.graphiql-toolbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--toolbar-width);
  width: var(--toolbar-width)
}
button.graphiql-toolbar-button.error {
    background: hsla(var(--color-error), var(--alpha-background-heavy));
  }
.graphiql-execute-button-wrapper {
  position: relative;
}

button.graphiql-execute-button {
  background-color: hsl(var(--color-primary));
  border: none;
  border-radius: var(--border-radius-8);
  cursor: pointer;
  height: var(--toolbar-width);
  padding: 0;
  width: var(--toolbar-width)
}

button.graphiql-execute-button:hover {
    background-color: hsla(var(--color-primary), 0.9);
  }

button.graphiql-execute-button:active {
    background-color: hsla(var(--color-primary), 0.8);
  }

button.graphiql-execute-button:focus {
    outline: hsla(var(--color-primary), 0.8) auto 1px;
  }

button.graphiql-execute-button > svg {
    color: white;
    display: block;
    height: var(--px-16);
    margin: auto;
    width: var(--px-16);
  }
button.graphiql-toolbar-menu {
  display: block;
  height: var(--toolbar-width);
  width: var(--toolbar-width);
}
